import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/accounting/reports';

export default {
    general_report(payload) {
        return axios.post(`${RESOURCE_NAME}/general_turnover`, payload)
    },
    general_report_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/general_turnover_xlsx`, payload, {responseType: 'blob'})
    },
    general_report_email(payload) {
        return axios.post(`${RESOURCE_NAME}/general_turnover_email`, {
            date_start: payload.date_start,
            date_end: payload.date_end,
            filters: payload.filters,
            email: payload.email
        })
    },
    general_report_by_code(payload) {
        return axios.post(`${RESOURCE_NAME}/general_turnover_by_code`, payload)
    },
    general_report_by_code_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/general_turnover_by_code_xlsx`, payload, {responseType: 'blob'})
    },
    general_report_by_code_email(payload) {
        return axios.post(`${RESOURCE_NAME}/general_turnover_by_code_email`, {
            date_start: payload.date_start,
            date_end: payload.date_end,
            filters: payload.filters,
            accounting_id: payload.accounting_id,
            email: payload.email
        })
    },
    accounting_card_report_by_code(payload) {
        return axios.post(`${RESOURCE_NAME}/accounting_card_report_by_code`, payload)
    },
    accounting_card_report_by_code_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/accounting_card_report_by_code_xlsx`, payload, {responseType: 'blob'})
    },
    accounting_card_report_by_code_email(payload) {
        return axios.post(`${RESOURCE_NAME}/accounting_card_report_by_code_email`,{
            date_start: payload.date_start,
            date_end: payload.date_end,
            filters: payload.filters,
            accounting_id: payload.accounting_id,
            email: payload.email
        })
    },
    salary_main_report(payload) {
        return axios.post(`${RESOURCE_NAME}/salary_main_report`, payload)
    },
    salary_accounting_list(payload) {
        return axios.post(`${RESOURCE_NAME}/salary_accounting_list`, payload)
    },
    salary_contribution_and_taxes(payload) {
        return axios.post(`${RESOURCE_NAME}/salary_contribution_and_taxes`, payload)
    },
    salary_total_contribution_and_taxes(payload) {
        return axios.post(`${RESOURCE_NAME}/salary_total_contribution_and_taxes`, payload)
    },
};